import { SwipeableDrawer } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { IMG_URL, dataService } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { Formik } from "formik";
import { addCouponSchema } from "../../../../validation/validation";
import { toast } from "react-toastify";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "600px",
  overflow: "auto"
};

// for table data

function createData(name, address, city, number, stutus, action) {
  return { name, address, city, number, stutus, action };
}

const rows = [
  createData(
    <Index.Box className="userlist-data">
      <img src={PagesIndex.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS",
    <Index.Box className="userdata-btn-flex">
      <Index.Button>
        <img src={PagesIndex.Svg.blueedit}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.trash}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.yelloweye}></img>
      </Index.Button>
    </Index.Box>
  ),

  createData(
    <Index.Box className="userlist-data">
      <img src={PagesIndex.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS",
    <Index.Box className="userdata-btn-flex">
      <Index.Button>
        <img src={PagesIndex.Svg.blueedit}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.trash}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.yelloweye}></img>
      </Index.Button>
    </Index.Box>
  ),

  createData(
    <Index.Box className="userlist-data">
      <img src={PagesIndex.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS",
    <Index.Box className="userdata-btn-flex">
      <Index.Button>
        <img src={PagesIndex.Svg.blueedit}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.trash}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.yelloweye}></img>
      </Index.Button>
    </Index.Box>
  ),

  createData(
    <Index.Box className="userlist-data">
      <img src={PagesIndex.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS",
    <Index.Box className="userdata-btn-flex">
      <Index.Button>
        <img src={PagesIndex.Svg.blueedit}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.trash}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.yelloweye}></img>
      </Index.Button>
    </Index.Box>
  ),
];

const offerType = ["Flat", "Free Product"]

export default function OfferManagement() {
  const [initialValues, setInitialValues] = useState({
    categoryId: null,
    minPurchase: "",
    promoCode: "",
    discount: "",
    subCategoryId: null,
    freeProductId: null,
    productId: null
  });
  const [formikValues, setFormikValues] = useState({});
  const [categoryListData, setCategoryListData] = useState([]);
  const [subCategoryListData, setSubCategoryListData] = useState([]);
  const [offerListData, setOffertListData] = useState([]);
  const [getAllProductsData, setGetAllProductsData] = useState([]);
  const [getAllFreeProductsData, setGetAllFreeProductsData] = useState([]);

  console.log(getAllFreeProductsData, "maulik132")
  const [deleteDataId, setDeleteDataId] = useState("")

  // add user modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // delete modal
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  // filter
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Index.Box
      className="filter-main"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    ></Index.Box>
  );

  const submitForm = (data) => {

    dataService
      .post(Api.AddOffers, data)
      .then((e) => {
        toast.success("Added");
        getAllDiscountList();
        handleClose();
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const deleteData = () => {
    dataService.delete(Api.DeleteOffers + "?id=" + deleteDataId).then(() => {
      toast.success("Delete");
      handleCloseDelete();
      getAllDiscountList();
    })
  }

  const getAllCategory = () => {
    dataService
      .get(Api.CategoryList)
      .then(({ data }) => {
        setCategoryListData(data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllDiscountList = () => {
    dataService
      .get(Api.GetAllCodes)
      .then(({ data }) => {
        setOffertListData(data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllSubCategory = (id) => {
    dataService
      .get(Api.SubCategoryList + "?categoryId=" + id)
      .then(({ data }) => {
        setSubCategoryListData(data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllProducts = () => {
    dataService
      .get(Api.GetProductsCategoryWithSub)
      .then(({ data }) => {
          setGetAllFreeProductsData(data.data);
          setGetAllProductsData(data.data)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getAllCategory();
    getAllDiscountList();
    getAllProducts()
  }, []);

  useEffect(() => {
    if (formikValues.categoryId) {
      getAllSubCategory(formikValues.categoryId);
    }
  }, [formikValues]);


  return (
    <>
      <Index.Box className="dashboard-content user-list-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Offers & Discount
          </Index.Typography>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="filter-main">
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Index.Box className="export-btn-main border-btn-main">
                    <Index.Button
                      className="export-btn border-btn"
                    // onClick={toggleDrawer(anchor, true)}
                    >
                      <img
                        src={PagesIndex.Svg.filter}
                        className="down-icon"
                        alt="download icon"
                      />
                      Filter
                    </Index.Button>
                  </Index.Box>
                  <SwipeableDrawer
                    className="filter-main"
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    <Index.Box className="filter-header">
                      <Index.Typography className="filter-title">
                        Filter
                      </Index.Typography>
                      <img
                        src={PagesIndex.Png.close}
                        className="filter-close-icon"
                        onClick={toggleDrawer(anchor, false)}
                      />
                    </Index.Box>
                    <Index.Box className="filter-inner-main">
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Name
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          City
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Status
                        </Index.FormHelperText>
                        <Index.Box className="checkbox-main filter-checkbox-main input-box">
                          <Index.FormControlLabel
                            control={<Index.Checkbox defaultChecked />}
                            label="Active"
                            className="checkbox-lable"
                          />
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Deactivate"
                            className="checkbox-lable"
                          />
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Pending"
                            className="checkbox-lable"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="filter-footer">
                      <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                        <Index.Button className="border-btn filter-cancel-btn">
                          Cancel
                        </Index.Button>
                        <Index.Button className="btn-primary filter-btn">
                          Filter
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </Index.Box>
            <Index.Box className="userlist-inner-btn-flex">
              <Index.Box className="export-btn-main border-btn-main">
                <Index.Button
                  className="export-btn border-btn"
                // onClick={handleOpenDelete}
                >
                  <img
                    src={PagesIndex.Svg.down}
                    className="down-icon"
                    alt="download icon"
                  />
                  Export
                </Index.Button>
              </Index.Box>
              <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={handleOpen}
                >
                  <img
                    src={PagesIndex.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="userlist-table-main page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table aria-label="simple table" className="table">
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Product Category
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Min. pur.
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Coupon Code
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Discount
                            </Index.TableCell>
                            {/* <Index.TableCell component='th' variant='th' className='table-th'>CITY</Index.TableCell>
                                                                                    <Index.TableCell component='th' variant='th' className='table-th'>NUMBER</Index.TableCell> */}
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              STATUS
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              ACTION
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {offerListData?.map((row) => (
                            <Index.TableRow
                              key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row?.categoryId?.categoryName}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row?.minPurchase}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row?.promoCode}
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                {row?.discount}
                              </Index.TableCell>
                              {/* <Index.TableCell component='td' variant='td' className='table-td'>{row.city}</Index.TableCell>
                                                                                          <Index.TableCell component='td' variant='td' className='table-td'>{row.number}</Index.TableCell> */}
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.isActive ? "Active" : "In-Active"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                <Index.Box className="userdata-btn-flex">
                                  <Index.Button
                                    onClick={() => {
                                      setInitialValues({ ...row, categoryId: row?.categoryId?._id, subCategoryId: row?.subCategoryId?._id });
                                      //   initialValues=row;
                                      handleOpen();
                                      // alert("Edit");
                                    }}
                                  >
                                    <img src={PagesIndex.Svg.blueedit}></img>
                                  </Index.Button>
                                  <Index.Button
                                    onClick={() => {
                                      setDeleteDataId(row._id)
                                      handleOpenDelete()
                                    }}
                                  >
                                    <img src={PagesIndex.Svg.trash}></img>
                                  </Index.Button>
                                  <Index.Button
                                    onClick={() => {
                                      // alert("View");
                                      handleOpen();
                                    }}
                                  >
                                    <img src={PagesIndex.Svg.yelloweye}></img>
                                  </Index.Button>
                                </Index.Box>
                              </Index.TableCell>
                            </Index.TableRow>
                          ))}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                  <Index.Box className="pagination-main">
                    <Index.Pagination
                      count={3}
                      variant="outlined"
                      shape="rounded"
                      className="pagination"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-ingredients"
        className="modal"
      >
        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
          <Formik
            enableReinitialize
            innerRef={(formikActions) =>
              formikActions ? setFormikValues(formikActions.values) : setFormikValues({})
            }
            validationSchema={addCouponSchema}
            initialValues={initialValues}
            onSubmit={submitForm}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isValid,
              dirty,
              touched,
              handleBlur,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                {console.log(values.ingredients, "maulik595")}
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Add
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={handleClose}
                  />
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Select Category
                  </Index.FormHelperText>
                  <Index.Box className="dropdown-box">
                    <Index.FormControl className="form-control drop-form-control">
                      <Index.Select
                        className="dropdown-select drop-select"
                        value={values.categoryId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="categoryId"
                        helperText={touched.categoryId && errors.categoryId}
                        error={Boolean(errors.categoryId && touched.categoryId)}
                      >
                        <Index.MenuItem value={null} className="drop-menuitem">
                          Select category
                        </Index.MenuItem>
                        {categoryListData?.map((e) => {
                          return (
                            <Index.MenuItem
                              value={e._id}
                              className="drop-menuitem"
                            >
                              {e.categoryName}
                            </Index.MenuItem>
                          );
                        })}
                      </Index.Select>
                      {/* <span><img src={Index.Svg.instagram} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Select Sub Category
                  </Index.FormHelperText>
                  <Index.Box className="dropdown-box">
                    <Index.FormControl className="form-control drop-form-control">
                      <Index.Select
                        className="dropdown-select drop-select"
                        value={values.subCategoryId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="subCategoryId"
                        helperText={
                          touched.subCategoryId && errors.subCategoryId
                        }
                        error={Boolean(
                          errors.subCategoryId && touched.subCategoryId
                        )}
                      >
                        <Index.MenuItem value={null} className="drop-menuitem">
                          Select Sub category
                        </Index.MenuItem>
                        {subCategoryListData?.map((e) => {
                          return (
                            <Index.MenuItem
                              value={e._id}
                              className="drop-menuitem"
                            >
                              {e.subCategoryName}
                            </Index.MenuItem>
                          );
                        })}
                      </Index.Select>
                      {/* <span><img src={Index.Svg.instagram} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Select Product
                      </Index.FormHelperText>
                      <Index.Box className="dropdown-box">
                        <Index.FormControl className="form-control drop-form-control">
                          <Index.Select
                            className="dropdown-select drop-select"
                            value={values.productId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="productId"
                            helperText={
                              touched.productId && errors.productId
                            }
                            error={Boolean(
                              errors.productId && touched.productId
                            )}
                          >
                            <Index.MenuItem value={null} className="drop-menuitem">
                              Select Free Product
                            </Index.MenuItem>
                            {getAllProductsData?.map((e) => {
                              return (
                                <Index.MenuItem
                                  value={e._id}
                                  className="drop-menuitem"
                                >
                                  {e.name}
                                </Index.MenuItem>
                              );
                            })}
                          </Index.Select>
                          {/* <span><img src={Index.Svg.instagram} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                {/* <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Offer Type
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      name="type"
                      helperText={touched.type && errors.type}
                      error={Boolean(errors.type && touched.type)}
                    />
                  </Index.Box>
                </Index.Box> */}
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Offer Type
                  </Index.FormHelperText>
                  <Index.Box className="dropdown-box">
                    <Index.FormControl className="form-control drop-form-control">
                      <Index.Select
                        className="dropdown-select drop-select"
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="type"
                        helperText={
                          touched.type && errors.type
                        }
                        error={Boolean(
                          errors.type && touched.type
                        )}
                      >
                        <Index.MenuItem value="" className="drop-menuitem">
                          Select Offer type
                        </Index.MenuItem>
                        {offerType?.map((e) => {
                          return (
                            <Index.MenuItem
                              value={e}
                              className="drop-menuitem"
                            >
                              {e}
                            </Index.MenuItem>
                          );
                        })}
                      </Index.Select>
                      {/* <span><img src={Index.Svg.instagram} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>
                {
                  formikValues.type === "Free Product" ?
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Select Free Product
                      </Index.FormHelperText>
                      <Index.Box className="dropdown-box">
                        <Index.FormControl className="form-control drop-form-control">
                          <Index.Select
                            className="dropdown-select drop-select"
                            value={values.freeProductId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="freeProductId"
                            helperText={
                              touched.freeProductId && errors.freeProductId
                            }
                            error={Boolean(
                              errors.freeProductId && touched.freeProductId
                            )}
                          >
                            <Index.MenuItem value="" className="drop-menuitem">
                              Select Free Product
                            </Index.MenuItem>
                            {getAllFreeProductsData?.map((e) => {
                              return (
                                <Index.MenuItem
                                  value={e._id}
                                  className="drop-menuitem"
                                >
                                  {e.name}
                                </Index.MenuItem>
                              );
                            })}
                          </Index.Select>
                          {/* <span><img src={Index.Svg.instagram} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box> : ""
                }
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Min Purchase
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      value={values.minPurchase}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      name="minPurchase"
                      helperText={touched.minPurchase && errors.minPurchase}
                      error={Boolean(errors.minPurchase && touched.minPurchase)}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    PromoCode
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      value={values.promoCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      name="promoCode"
                      helperText={touched.promoCode && errors.promoCode}
                      error={Boolean(errors.promoCode && touched.promoCode)}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Discount amount
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      value={values.discount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      name="discount"
                      helperText={touched.discount && errors.discount}
                      error={Boolean(errors.discount && touched.discount)}
                    />
                  </Index.Box>
                </Index.Box>

                <Index.Box className="modal-user-btn-flex">
                  <Index.Box className="discard-btn-main border-btn-main">
                    <Index.Button className="discard-user-btn border-btn">
                      Discard
                    </Index.Button>
                  </Index.Box>
                  <Index.Box className="save-btn-main border-btn-main">
                    <Index.Button
                      type="submit"
                      className="save-user-btn border-btn"
                    >
                      <img
                        src={PagesIndex.Svg.save}
                        className="user-save-icon"
                      ></img>
                      Save
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Modal >

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-ingredients"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
          <Index.Box className="modal-circle-main">
            <img src={PagesIndex.Svg.closecircle} className="user-circle-img" />
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure?
          </Index.Typography>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            Do you really want to delete these records? This process cannot be
            undone.
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button
              className="modal-cancel-btn modal-btn"
              onClick={handleCloseDelete}
            >
              Cancel
            </Index.Button>
            <Index.Button onClick={() => deleteData()} className="modal-delete-btn modal-btn">
              Delete
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
