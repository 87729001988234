import axios from "axios";

// live--

const API_ENDPOINT = "https://api.vittzios.com/api";
const IMG_URL = "https://api.vittzios.com/api/images/"

// const API_ENDPOINT = "http://localhost:3033/api/";
// const IMG_URL = "http://localhost:3033/api/images/"

//local--
// const API_ENDPOINT = "http://192.168.29.136:3027/api/";

// const API_ENDPOINT = "http://localhost:3027/api/";
// const API_IMAGE_URL_ENDPOINT = process.env.REACT_APP_IMAGE_URL;

const dataService = axios.create({
  baseURL: API_ENDPOINT,
});

dataService.interceptors.request.use(
  (config) => {
    config.headers.auth = localStorage.getItem("token");
    return config;
  },
  (error) => {
    return error;
  }
);

export { dataService, IMG_URL };
